import logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="fotAbout">
                <img src={logo} alt="Rosalin Child Development Centre" />
              </div>
            </div>
            <div className="col-12 showMobile">
              <div className="fotLinks">
                <a href="https://www.rosalinchilddevelopment.com">Home</a>
                <a href="https://www.rosalinchilddevelopment.com/about">About Us</a>
                <a href="https://www.rosalinchilddevelopment.com/contact">Contact Us</a>
                <a href="https://www.rosalinchilddevelopment.com/job-vacancies">Job Vacancies</a>
                <a href="#">Portfolio</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Terms & Conditions</a>
                <a href="https://www.rosalinchilddevelopment.com/book-appointment">
                  Book Appointment
                </a>
                <a href="https://www.rosalinchilddevelopment.com/occupational-therapy-in-lucknow">
                  Occupational Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/behavioral-therapy-in-lucknow">
                  Behavioral Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/cerebral-palsy-treatment-in-lucknow">
                  Cerebral Palsy Treatment in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/hyperactivity-treatment-in-lucknow">
                  Hyperactivity Treatment in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/aba-therapy-in-lucknow">
                  ABA Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/speech-therapy-in-lucknow">
                  Speech Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/services">Services</a>
                <a href="https://www.rosalinchilddevelopment.com/events">Events</a>
                <a href="https://www.rosalinchilddevelopment.com/photo-gallery">Photo Gallery</a>
                <a href="https://www.rosalinchilddevelopment.com/video-gallery">Video Gallery</a>
                <a href="https://www.rosalinchilddevelopment.com/parental-education">
                  Parental Education
                </a>
                <a href="https://www.rosalinchilddevelopment.com/client-feedback">
                  Client Feedback
                </a>
              </div>
            </div>
            <div className="col-sm-3 col-md-2 hideMobile">
              <div className="fotLinks">
                <a href="https://www.rosalinchilddevelopment.com">Home</a>
                <a href="https://www.rosalinchilddevelopment.com/about">About Us</a>
                <a href="https://www.rosalinchilddevelopment.com/contact">Contact Us</a>
                <a href="https://www.rosalinchilddevelopment.com/job-vacancies">Job Vacancies</a>
              </div>
            </div>
            <div className="col-sm-3 col-md-2 hideMobile">
              <div className="fotLinks">
                <a href="#">Privacy Policy</a>
                <a href="#">Terms & Conditions</a>
                <a href="https://www.rosalinchilddevelopment.com/book-appointment">
                  Book Appointment
                </a>
                <a href="https://www.rosalinchilddevelopment.com/services">Services</a>
              </div>
            </div>
            <div className="col-sm-3 col-md-4 hideMobile">
              <div className="fotLinks">
                <a href="https://www.rosalinchilddevelopment.com/occupational-therapy-in-lucknow">
                  Occupational Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/behavioral-therapy-in-lucknow">
                  Behavioral Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/cerebral-palsy-treatment-in-lucknow">
                  Cerebral Palsy Treatment in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/hyperactivity-treatment-in-lucknow">
                  Hyperactivity Treatment in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/aba-therapy-in-lucknow">
                  ABA Therapy in Lucknow
                </a>
                <a href="https://www.rosalinchilddevelopment.com/speech-therapy-in-lucknow">
                  Speech Therapy in Lucknow
                </a>
              </div>
            </div>
            <div className="col-sm-3 col-md-2 hideMobile">
              <div className="fotLinks">
                <a href="https://www.rosalinchilddevelopment.com/events">Events</a>
                <a href="https://www.rosalinchilddevelopment.com/photo-gallery">Photo Gallery</a>
                <a href="https://www.rosalinchilddevelopment.com/video-gallery">Video Gallery</a>
                <a href="https://www.rosalinchilddevelopment.com/parental-education">
                  Parental Education
                </a>
                <a href="https://www.rosalinchilddevelopment.com/client-feedback">
                  Client Feedback
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="fotCon">
                <p className="tbAdd">
                  <FontAwesomeIcon icon="location-dot" /> D-1/106, Vibhav Khand, Gomti Nagar,
                  Lucknow, Uttar Pradesh 226010
                </p>
                <p>
                  <FontAwesomeIcon icon="mobile-screen-button" /> 8177023887, 7275504855
                </p>
                <p>
                  <a href="mailto:enquiry@rosalinchilddevelopment.com">
                    <FontAwesomeIcon icon="envelope" /> enquiry@rosalinchilddevelopment.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyRig">
        <div className="container">
          <p>
            © 2017 - 2022, Rosalin Child Development And Rehabilitation Centre. All Rights Reserved
          </p>
          <p>
            Developed By{" "}
            <a href="https://www.webniter.com/" target="_blank">
              Webniter
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default footer;
