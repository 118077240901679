import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./common/Header";
import ContentIndex from "./index/Content";
import About from "./About";
import Services from "./Services";
import OccupationalTherapyInLucknow from "./OccupationalTherapyInLucknow";
import BehavioralTherapyInLucknow from "./BehavioralTherapyInLucknow";
import CerebralPalsyTreatmentInLucknow from "./CerebralPalsyTreatmentInLucknow";
import HyperactivityTreatmentInLucknow from "./HyperactivityTreatmentInLucknow";
import AbaTherapyInLucknow from "./AbaTherapyInLucknow";
import SpeechTherapyInLucknow from "./SpeechTherapyInLucknow";
import NoPage from "./NoPage";
import Events from "./Events";
import Footer from "./common/Footer";
import PhotoGallery from "./PhotoGallery";
import VideoGallery from "./VideoGallery";
import ParentalEducation from "./ParentalEducation";
import BlogSingle from "./BlogSingle";
import ClientFeedback from "./ClientFeedback";
import ContactUs from "./ContactUs";
import JobVacancies from "./JobVacancies";
import BookAppointment from "./BookAppointment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { stringToUrl } from "./common/Library";
import {
  faCheckSquare,
  faCoffee,
  faMobileScreenButton,
  faLocationDot,
  faEnvelope,
  faUserDoctor,
  faHeadSideCough,
  faBookMedical,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

library.add(
  faCheckSquare,
  faCoffee,
  faMobileScreenButton,
  faLocationDot,
  faEnvelope,
  faUserDoctor,
  faHeadSideCough,
  faBookMedical,
  faAngleRight,
);
function App() {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const apiUrl = "https://www.rosalinchilddevelopment.com/administration/home/b2c_blogs";

    axios.get(apiUrl).then((response) => {
      setBlogs(response.data);
    });
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/photo-gallery" component={PhotoGallery} />
          <Route exact path="/video-gallery" component={VideoGallery} />
          <Route exact path="/parental-education" component={ParentalEducation} />
          {blogs?.map((blog) => (
            <Route
              exact
              path={`/parental-education/${stringToUrl(blog.heading)}`}
              blog={blog}
              render={() => <BlogSingle blog={blog} blogs={blogs} />}
            />
          ))}
          <Route exact path="/client-feedback" component={ClientFeedback} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/job-vacancies" component={JobVacancies} />
          <Route exact path="/book-appointment" component={BookAppointment} />
          <Route
            exact
            path="/occupational-therapy-in-lucknow"
            component={OccupationalTherapyInLucknow}
          />
          <Route
            exact
            path="/behavioral-therapy-in-lucknow"
            component={BehavioralTherapyInLucknow}
          />
          <Route
            exact
            path="/cerebral-palsy-treatment-in-lucknow"
            component={CerebralPalsyTreatmentInLucknow}
          />
          <Route
            exact
            path="/hyperactivity-treatment-in-lucknow"
            component={HyperactivityTreatmentInLucknow}
          />
          <Route exact path="/aba-therapy-in-lucknow" component={AbaTherapyInLucknow} />
          <Route exact path="/speech-therapy-in-lucknow" component={SpeechTherapyInLucknow} />
          <Route exact path="/" component={ContentIndex} />
          <Route path="*" component={NoPage} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
